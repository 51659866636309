<div>
  <ejs-treeview
    #menuTree
    id="menuTree"
    cssClass="main-treeview"
    [fields]="fields"
    expandOn="Click"
    (nodeSelected)="onMenuItemClicked($event)"
  >
  <ng-template #nodeTemplate let-data>
  <div class="tree-node">
      <div class="e-list-icon images"><span [class]='data.icon'></span></div>
      <span>{{ data.name }}</span>
    </div>
 </ng-template>
  </ejs-treeview>
</div>
