import { inject, Injectable } from "@angular/core";
import { EventActionService } from "./event-action.service";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root' 
})
export class TabUtilsService {   
    private readonly _eventActionService : EventActionService = inject(EventActionService);
    private readonly _tabToasterService: ToastrService = inject(ToastrService);

    constructor() { }

    /**
     * Navigates to a screen in the same tab.
     * @param eventParams The event parameters object containing the screen ID and parameters.
     */
    navigateToScreen(eventParams: any) {
        // Open the screen in the same tab using the openScreen method of the eventActionService service
        this._eventActionService.openScreen('navigationInSameTab', eventParams.screenId, eventParams.parameters);
    }

    /**
     * Navigates to a screen in a new window.
     * @param eventParams The event parameters object containing the screen ID and parameters.
     */
    navigateToNewWindowScreen(eventParams: any) {
        // Open the screen in a new window using the openScreen method of the eventActionService service
        this._eventActionService.openScreen('navigationInNewTab', eventParams.screenId, eventParams.parameters);
    }


    /**
     * Shows a notification message using the Toastr service.
     * @param eventParams An object containing the notification message details.
     *                    The object should have the following properties:
     *                    - type: The type of notification to show. Can be 'success', 'error', 'info', or 'warning'.
     *                    - message: The message to show in the notification.
     *                    - title: The title of the notification. Optional.
     *                    - configuration: An object containing additional configuration options for the notification. Optional.
     */
    notify(eventParams: any) {
        eventParams.configuration = {
            ...eventParams.configuration,
            positionClass: 'toast-bottom-right'  // Set the position to bottom right
        };
        
        if((this._tabToasterService as any)[eventParams.type]) {
            (this._tabToasterService as any)[eventParams.type](eventParams.message, eventParams.title, eventParams.configuration);
        }
    }
}
