import { bootstrapApplication } from '@angular/platform-browser';
import '@angular/compiler';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlcdXRdRmReVUFwX0I='
);
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
