<div class="top-header">
  <!-- (click)="leftMenuSlide()" -->

  <div class="menu-bar-btn" *ngIf="!isHorizontalLayout" (click)="leftMenuSlide()">
    <i class="e-line-normal e-icons"></i>
  </div>
  <!-- App switcher -->
  <div class="col-1 d-flex" *ngIf="isHorizontalLayout">
 <app-switcher></app-switcher>
 <div class="horizontallogo">
  <!-- Dynamic logo bind -->
  <!-- <img [src]="orgInfo?.Org_Menu_Logo" style="width: 100px;"> -->
  <!-- Static Logo bind -->
  <img src="../../../assets/images/app/pms-logo.png" style="max-width: 40px;margin-left: 2rem;">

 </div>
</div>
  <!--horizontal menu -->
  <div class="menu-control horizontal-menu col-8 p-0" *ngIf="isHorizontalLayout">
    <ejs-menu
    #menuObj
    [items]="menuItems"
    (select)="onMenuItemClicked($event)"
    class="ejsMenu"
    [enableScrolling]="enablescroll"
    [cssClass]="menuCssClass"
   
  ></ejs-menu>


  </div>
  <!-- end -->
  <div class="top-header-right">
    <div>
      <select class="form-control" (change)="onChangeEnvironment($event)" *ngIf="isBuildAndDevelopeModeUsercanAccess()">
        <option value="Development" selected>Development</option>
        <option value="QA">QA</option>
        <option value="Production" >Production</option>
      </select>
    </div>
    <div class="top-header-icon" (click)="rebuildApp()" *ngIf="isBuildAndDevelopeModeUsercanAccess()">
      <i style="font-size: 18px"  [ngClass]="showLoader? 'loader-build' : '' " class="e-repeat e-icons"></i>
    </div>
    <div class="top-header-icon" (click)="onFullScreenClick()">
      <i
        [ngClass]="{
          'e-full-screen e-icons': !fullScreen,
          'fullWidthModal': fullScreen
        }"
        style="font-size: 18px">
      </i>
    </div>
    <!-- (click)="showNotification()" -->
    <div class="top-header-icon" (click)="showNotification()">
      <i style="font-size: 24px" class="notification-icon"></i>
      <!-- Badge for notification count -->
      <span class="badge-container">
        <span class="badge">{{ notificationCount }}</span>
        <!-- <span class="badge">12</span> -->
      </span>
    </div>
    <!-- (click)="userDropDown()" -->
    <div class="top-header-icon user-dd-menu" (click)="userDropDown()">
      <i style="font-size: 24px" class="user-icon"></i>
    </div>
  </div>
</div>
<app-notification *ngIf="isNotificationListVisible" [headlessService]="headlessService"
(removeNotificationEvent)="handleRemoveNotification($event)"
(markAsReadEvent)="handleMarkAsRead($event)"
>
</app-notification>
<app-user-drop-down *ngIf="isUserDropDownVisible"></app-user-drop-down>
