
import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpResponse, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators'
import { LoadingService } from '../services/loading.service';

// export const HttpRequestInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    export  const HttpRequestInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
  {
    
    const _loading = inject(LoadingService);
    
    _loading.setLoading(true, req.url);

    return next(req).pipe(
        map((evt: HttpEvent<any>) => {
            if (evt instanceof HttpResponse) {
            _loading.setLoading(false, req.url);
            }
            return evt;
        }),
        catchError((err) => {
            _loading.setLoading(false, req.url);
            return throwError(err);
        })
    );
}
