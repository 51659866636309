<div *ngIf="isUserPopupVisible" class="user-drop-down" #userPopup>
  <span class="welcome"><b>{{loginData?.FirstName}} {{loginData?.LastName}}</b>
    <br/> Role: {{roleNames}}</span>
  <ul>
    <!-- <li (click)="getProfile()"><i class="user-icon" ></i>Profile</li> -->
    <!-- <li><i class="e-settings e-icons" style="font-size: 18px;"></i>Setting</li> -->
    <li (click)="onResetPassword()"><i class="e-settings e-icons" style="font-size: 18px;"></i>Reset Password</li>
    <li (click)="onOraganizationSetting()"><i class="e-settings e-icons" style="font-size: 18px;"></i>Organization Setup</li>
    <!-- <li> -->
      <!-- <i class="e-chevron-left e-icons"></i> -->
      <!-- <span class="status online"></span>
        Status -->
      <!-- <ul>
        <li><span class="status online"></span>Online</li>
        <li><span class="status away"></span>Away</li>
        <li><span class="status busy"></span>Busy</li>
        <li><span class="status invis"></span>Invisible</li>
      </ul> -->
    <!-- </li> -->
    <li (click)="onSignout()"><i class="e-back e-icons"></i>Signout</li>
  </ul>
</div>
