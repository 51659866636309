@if (isVisible) {
  <div class="modal fade show d-block" role="dialog"> 
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ config.title }}</h5>
          <button type="button" class="close" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ config.message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onConfirm()">Ok</button>
          <button type="button" class="btn btn-danger" (click)="onCancel()">Cancel</button>
        </div>
      </div>
    </div>
     <div class="modal-backdrop fade show"></div>
  </div>
}