<!-- <div class="main-container">
  <div class="left-container" [ngClass]="isPanelOpen ? 'openLeftPanel' : 'closeLeftPanel' ">
    <div class="menu-top-logo">
      <img src="../../assets/images/login-logo.svg">
    </div>
    <div class="left-navigation" >
        <app-left-navigation></app-left-navigation>
    </div>
  </div>
  <div class="right-container" [ngClass]="isPanelOpen ? 'openLeftPanel' : 'closeLeftPanel' ">
    <app-top-header (panelOpenClose)="panelOpenClose($event)"></app-top-header>
    <app-form-designer></app-form-designer>
  </div>
</div> -->

<div class="main-container">
  <div class="left-container" *ngIf="organizationInfo?.ShowHorizontalLayout == 'false' || organizationInfo?.ShowHorizontalLayout == false " [ngClass]="isPanelOpen ? 'openLeftPanel' : 'closeLeftPanel' ">
    <div class="menu-top-logo"  #popup>
      <app-switcher></app-switcher>

      <img [src]="organizationInfo?.Org_Menu_Logo">
    </div>
    <div class="left-navigation" >
      <app-menu-bar></app-menu-bar>
    </div>
  </div>
  <div class="right-container" [ngClass]="(isPanelOpen  && (organizationInfo?.ShowHorizontalLayout == 'false' || organizationInfo?.ShowHorizontalLayout == false)) ? 'openLeftPanel' : 'closeLeftPanel' ">
      <app-header (panelOpenClose)="panelOpenClose($event)"></app-header>
    <!-- <app-form-designer></app-form-designer> -->
    <router-outlet></router-outlet>
  </div>
</div>



<!-- <router-outlet></router-outlet>
<app-header></app-header>
<app-menu-bar></app-menu-bar> -->
