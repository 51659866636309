import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
    
    public conformationEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    public dialogEmitter: EventEmitter<any> = new EventEmitter<any>();
    
    /**
     * Opens a confirmation dialog with the given title and message.
     * 
     * This function will emit a value on the confirmation dialog emitter when the dialog is closed.
     * The value emitted will be a boolean indicating whether the dialog was confirmed or canceled.
     * 
     * @param {Object} config - The configuration for the dialog. Must have a 'title' and a 'message' property.
     * @returns {Observable<boolean>} - An observable that emits a boolean indicating whether the dialog was confirmed or canceled.
     */
    confirm(config?: { title: string; message: string }): Observable<boolean> {
        return new Observable((observer) => {
            this.dialogEmitter.emit(config);
            this.conformationEmitter.subscribe((result: boolean) => {
                observer.next(result);
                observer.complete();
            })        
        });
    }
}


//   public confirmationDialogContainer: ViewContainerRef = inject(ViewContainerRef);
// constructor(public confirmationDialogContainer: ViewContainerRef) {}
//   openDialog(config: { title: string; message: string }): Promise<boolean> {
//     return new Promise((resolve) => {
//       // Create the component dynamically
//        const dialogComponentRef = this.confirmationDialogContainer.createComponent(ConfirmationDialogComponent);

//       dialogComponentRef.instance.config = config;

//       dialogComponentRef.instance.confirmed.subscribe((result: boolean) => {
//         dialogComponentRef.destroy();
//         resolve(result);
//       });
//       dialogComponentRef.instance.show();
//     });
//   }