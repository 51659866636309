import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TabUtilsService } from '../services/tab-utils.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private readonly authService = inject(AuthService)
    private readonly tabUtilsService = inject(TabUtilsService)
  constructor( private _router: Router, ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        // Handle successful responses here
        if (event instanceof HttpResponse) {
          if(event?.body?.Errors?.length > 0 && event?.body?.StatusCode != '200'){
            this.tabUtilsService.notify({
              type: 'error',
              message: event.body.Errors[0],
              title: 'Error'
            })
          }

          // You can perform actions based on the response data here, such as modifying the response or logging it
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.signOut();
          if (this._router.url !== '/sign-in' && this._router.url !== '/') {
            console.log("Navigate on Sign-in")
           // this._router.navigate(['sign-in']); comment not passing query params for after successful sign in redirection 
            this._router.navigate(['sign-in'],{queryParams: { redirectURL: this._router.url.slice(1)}});
          }
        }
        this.tabUtilsService.notify({
          type: 'error',
          message: Array.isArray(error?.error?.Errors) && error.error.Errors.length > 0 ? error.error.Errors[0] : 'Something went wrong',
          title: 'Error'
        });
        
        return throwError(error);
      })
    );
  }
}