import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { noAuthGuard } from './core/guards/noAuth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { OrgInfoResolver } from './core/resolver/orgInfoResolver';
import { DataResolver } from './core/resolver/data.resolver';
export const routes: Routes = [
    // empty route for lazy loading
    // when empty route is matched, it will redirect to example and verify if user is authenticated via authguard
    { path: '', pathMatch: 'full', redirectTo: 'screen/' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [noAuthGuard],
        canActivateChild: [noAuthGuard],
        // component: LayoutComponent,
        resolve: { orgInfoData: OrgInfoResolver },
        children: [
            {
                path: 'sign-in',
                loadComponent: () => import('./features-modules/auth/sign-in/sign-in.component').then(m => m.SignInComponent),
            },
            {
                path: 'sign-up',
                loadComponent: () => import('./features-modules/auth/sign-up/sign-up.component').then(m => m.SignUpComponent),
            },
            {
                path: 'forgot-password',
                loadComponent: () => import('./features-modules/auth/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
            },
            {
                path: 'reset-password',
                loadComponent: () => import('./features-modules/auth/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
            },
        ],
    },
    
    // Admin routes
    {
        path: '',
        canActivate: [authGuard],
        canActivateChild: [authGuard],
        component: LayoutComponent,
        resolve: { resolvedData: DataResolver },
        children: [
            {
                path: 'screen/:screenId',
                loadComponent: () => import('./features-modules/formio/tab-form-renderer/tab-form-renderer.component').then(m => m.TabFormRendererComponent),
                data: { screenRenderingMode: 'MainScreen' }
            },
            {
                path: 'change-password',
                loadComponent: () => import('./features-modules/auth/change-password/change-password.component').then(m => m.ChangePasswordComponent),
            },
        ],
    },
    // route after signup
    {
        path: 'org-setup/:screenId',
        resolve: { resolvedData: DataResolver },
        loadComponent: () => import('./features-modules/formio/tab-form-renderer/tab-form-renderer.component').then(m => m.TabFormRendererComponent),
        data: { screenRenderingMode: 'MainScreen' }
    },

    // page not found route
    { path: '**', redirectTo: '' },
];
