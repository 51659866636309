<div class="menu-top-logo" #popup>
    <button *ngIf="isAppListVisible" class="app-list-button" (click)="toggleContentVisibility()">
      <i class="e-icons e-selection"></i>
    </button>
    <div *ngIf="isPopupVisible" class="position-absolute sidebar" >
      <div class="sidebar-icon">
        <ul class="list-unstyled">
          <div *ngIf="isLoading" class="loader-container">
            <!-- Loader HTML/CSS -->
            <div class="loader-class"></div>
          </div>
          <li class="sidebar-icon-item" *ngFor="let orgName of tenantList"
            [ngClass]="{'selected': selectedTenant === orgName.TenantId}" (click)="getAppListByTenant(orgName.TenantId)">
            {{ getInitials(orgName.OrganizationName) }}
          </li>
        </ul>
      </div>

      <div class="main-sidebar-contents">
        <div class="profile">
          <div class="profile-info">
            <h2>{{this.appList[0]?.TenantId?.OrganizationName}}</h2>
          </div>
        </div>
        <div class="apps mt-2">
          <h3 class="app-main-title-layout">YOUR APPS</h3>
          <ul class="list-unstyled custom-list-box">
            <li class="p-2" *ngFor="let apps of appList" (click)="selectedApp(apps, selectedTenant)"
              [ngClass]="{'selected': selectedAppId === apps.Id}">
              <img src="../../assets/images/app/app-img.png" alt="App Logo">
               <p>{{apps.AppName}}</p>
            </li>
          </ul>
          <div class="add-custom-view-link" (click)="addApp()">
            <i class="e-icons e-plus"></i> 
         Add More Apps
          </div>
        </div>

      </div>
    </div>

    <!-- <img [src]="orgInfo?.Org_Menu_Logo"> -->
  </div>