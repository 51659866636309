import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild, inject, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { Constants } from '../../../core/const/constants';

@Component({
  selector: 'app-user-drop-down',
  templateUrl: './user-drop-down.component.html',
  styleUrl: './user-drop-down.component.scss',
  imports: [CommonModule],
  standalone: true
})
export class UserDropDownComponent implements OnInit {
  isUserPopupVisible = true;
  private _router: Router = inject(Router);
  private readonly authService = inject(AuthService);
  private _localStorageService = inject(LocalStorageService);
  private _sessionStorageService = inject(SessionStorageService);
  @ViewChild('userPopup') popup: ElementRef | undefined;
  loginData: any;
  roleNames: any;

  /**
   * Initializes the component after Angular initializes the data-bound input properties.
   * The ngOnInit() method is called once, after the constructor, and immediately after the
   * first ngOnChanges().
   * It is an appropriate place to put initialization code.
   */
  ngOnInit() {
    // Fetch the login data from local storage and assign it to the loginData property.
    this.fetchLoginData();
  }

  fetchLoginData() {
    // get the login data from local storage and assign it to the data.
    const data = this._localStorageService.getLocalStorage(StorageConstants.userInfo);
    if (data) {
      this.loginData = JSON.parse(data).user.PersonData;
      this.roleNames = JSON.parse(data).user.Roles.map((role: any) => role.RoleName).join(', ');
    }
    
  }


  /**
   * Closes the user dropdown popup.
   *
   * This function sets the `isUserPopupVisible` property to `false`,
   * which hides the user dropdown popup when called.
   */
  closeUserPopup() {
    // Set the 'isUserPopupVisible' property to false to hide the user dropdown popup
    this.isUserPopupVisible = false;
  }

  @HostListener('document:mousedown', ['$event'])
  /**
   * Listen for a click event on the document and check if the click was outside the user dropdown popup.
   * If the click was outside the popup, close the popup.
   *
   * @param {any} event - The click event.
   */
  onOutsideClick(event: any) {
    // Check if the user dropdown is visible and the click is outside the popup
    if (this.isUserPopupVisible && this.popup && !this.popup.nativeElement.contains(event.target)) {
      // Close the user dropdown popup
      this.closeUserPopup();
    }
  }

  /**
   * Navigates to the change-password page 
   * when the "Reset Password" item is clicked.
   */
  onResetPassword() {
    this._router.navigate(['change-password']);
    this.closeUserPopup();
  }

  /**
   * Signs out the user by calling the signOut method of the AuthService.
   * Upon successful sign out, navigates to the sign-in page.
   */
  onSignout() {
    // Call the signOut method of the AuthService and subscribe to the returned Observable
    this.authService.signOut().subscribe(() => {
      // Upon successful sign out, navigate to the sign-in page
      this._router.navigate(['sign-in']);
      this.closeUserPopup();
    });
    
  }
  getProfile() {
  const orgInfo =  JSON.parse(this._localStorageService.getLocalStorage(StorageConstants.orgInfo))
  const userInfo =  JSON.parse(this._localStorageService.getLocalStorage(StorageConstants.userInfo))
  if (orgInfo && orgInfo.profileScreenID) {

      if (orgInfo.profileScreenID) {
          // If profileScreenID exists, navigate to the specified screen
          this._router.navigateByUrl('/screen/' + orgInfo?.profileScreenID + '?Id=' + userInfo?.user?.Attributes?.Id);
          this.closeUserPopup();
      } else {
      }
  } else {
  }
  }
  onOraganizationSetting(){
    this._router.navigateByUrl( Constants.ORGINFO_SETTING_SCREEN + '?Id=' + this._sessionStorageService.getSessionStorage(StorageConstants.TenantId));
  }
}
