import { inject, Injectable } from "@angular/core";
import { getAppObjectFromDataSourceQueryID, getAppObjectFromSystemDBTableName, getViewAppObjectDetailsFromDSQID, getDataFromDataSourceQueryID, getFieldsType, SessionStorageService, TabGetService,StorageConstants, getAppObject, AppHelper, Common, IdbService } from "@techextensor/tab-core-utility";
import { firstValueFrom, Observable } from "rxjs";
import { Constants } from "../const/constants";

@Injectable({
    providedIn: 'root'
})
export class HelperFunctionService {
    private readonly tabGetService: TabGetService = inject(TabGetService);
    private readonly sessionStorageService: SessionStorageService = inject(SessionStorageService);
    private readonly appHelper: AppHelper = inject(AppHelper);
    private readonly idbService: IdbService = inject(IdbService);

    constructor() { }

    /**
     * Retrieves data from the specified data source query (DSQ) asynchronously.
     *
     * @param {string} dsqId - The ID of the DSQ to fetch data from.
     * @param {any} reqtokens - The request tokens to include in the payload. Optional.
     * @param {any} params - Additional parameters to include in the payload. Optional.
     *
     * @returns {Observable<any>} A Promise that resolves to the result of the DSQ execution.
     */
    public getDSQData(dsqId: string, reqtokens?: any, params?: any): Observable<any> {
        return new Observable((observer) => {
            try {
                const appObject = getAppObjectFromDataSourceQueryID(dsqId)?.ObjectName;
                const dsq = getDataFromDataSourceQueryID(dsqId)?.QueryName;

                if (!dsq || !appObject) {
                    throw new Error('DSQ or AppObject not found');
                }

                const payload = {
                    AppObjectName: appObject,
                    DSQName: dsq,
                    Reqtokens: reqtokens,
                    ...params
                };

                this.tabGetService.executeRAWDSQWithName(payload).subscribe((result: any) => {

                    let data = result;
                    if( !result?.IsSuccess || result?.StatusCode !== "200"){
                       console.error('Invalid API Response');
                       data = null;
                    }

                    observer.next(data);
                    observer.complete();

                });

            } catch (error) {
                console.error(error);
                observer.next(null);
                observer.complete();
            }
        });
    }

    /**
     * Retrieves fields from the specified data source query (DSQ) asynchronously.
     * It generates the application object fields from the DSQ and returns them
     * as an Observable.
     *
     * @param {string} dsqId - The ID of the DSQ to fetch fields from.
     * @returns {Observable<any>} An Observable that emits the fields or null if an error occurs.
     */
    public getFieldsFromDSQ(dsqId: string): Observable<any> {
        // Create an Observable that generates the application object fields from the DSQ
        return new Observable((observer) => {
            this.generateAppObjectFields(dsqId, 'DSQ')
                .then((fields: any) => {
                    // If the fields are generated successfully, emit the fields and complete the Observable
                    observer.next(fields);
                    observer.complete();
                })
                .catch((error: any) => {
                    // If an error occurs, log the error and emit null and complete the Observable
                    console.log('An error occurred while generating app object fields:', error);
                    observer.next(null);
                    observer.complete();
                });
        });
    }

    /**
     * Asynchronously generates the application object fields from the DSQ specified by the given ID.
     *
     * @param {string} dsqId - The ID of the DSQ to generate the fields from.
     * @returns {Promise<any>} A Promise that resolves to the processed application object fields.
     * @throws {Error} If the app object or fields are invalid.
     */
    private async generateAppObjectFields(Id: string, type: string): Promise<any> {
        let appObject;
        
        // Get the app object
        if(type == "DSQ")
            appObject = getAppObjectFromDataSourceQueryID(Id);
        else if (type == "AppObject")
            appObject = getAppObject(Id);

        // Check if the app object or fields are invalid
        if (!appObject || !appObject?.Fields)
            throw new Error('Invalid app object or fields not found');

        // Process the application object fields and return the result
        else
            return this.processAppObjectField(appObject.Fields, "","");
    }

    /**
     * Processes the application object fields and returns an array of field structures.
     * Recursively processes the fields if they have children (i.e., nested lookup fields).
     *
     * @param {any} fields - The application object fields to process.
     * @param {string} parent - The parent field ID used to generate the field structure ID.
     * @returns {any[]} An array of field structures.
     */
    private processAppObjectField(fields: any, parent: string,prefix?:any): any[] {
        if (!fields) return [];
        return fields.flatMap((field: any) => this.getFieldStructure(field, parent,prefix));
    }

    /**
     * Generates a single field structure from the given field object.
     * If the field has children (i.e., nested lookup fields), recursively processes them.
     *
     * @param {any} field - The field object to generate the structure from.
     * @param {string} parent - The parent field ID used to generate the field structure ID.
     * @returns {any[]} An array of field structures.
     */
    private getFieldStructure(field: any, parent: string,prefix?:any): any[] {
        const fieldStructure: any = {
            // Generate a unique ID for the field by appending the parent ID if it exists
            id: prefix !== '' ? parent + '_' + field.ID : field.ID,
            // Use the display name of the field
            name: field.FieldName,
            // Determine the type of the field based on its data type and whether it has a lookup
            fieldType: getFieldsType(field?.FieldType?.DataType, field.hasOwnProperty('LookUpDetails')),
            // Mark if the field has children (i.e., nested lookup fields)
            hasChildren: field.hasOwnProperty('LookUpDetails') && field?.LookUpDetails? true:false,
            // Store the parent ID if it exists
            parentID: parent !== '' ? parent : null,
            lookUpText: prefix? prefix + '.' + field.FieldName: field.FieldName,
        };

        if (field?.LookUpDetails) {
            // If the field has children, retrieve the lookup fields
            const lookupFields = getAppObjectFromSystemDBTableName(field.LookUpDetails.LookupObject)?.Fields;
            if (lookupFields) {
                // Recursively process the lookup fields and add the results to the field structure
                return [
                    fieldStructure,
                    ...this.processAppObjectField(lookupFields, fieldStructure.id,fieldStructure.lookUpText)
                ];
            }
            else {
                console.warn('Lookup fields not found for:', field.LookUpDetails.LookupObject);
            }
        }

        // Return the single field structure if it has no children
        return [fieldStructure];
    }

    public manageScreenContextData(dsqId:string,selectedRowsData:any) {
        const viewAppObjectDetailsOfGrid = getViewAppObjectDetailsFromDSQID(dsqId);
        const screenContext = this.sessionStorageService.getSessionStorage(StorageConstants.screenContext) || '{}';
        const updatedScreenContext = {
          ...JSON.parse(screenContext),
          [viewAppObjectDetailsOfGrid?.SystemDBTableName]: {
            ...JSON.parse(screenContext)[viewAppObjectDetailsOfGrid?.SystemDBTableName],
            activeRowsData: selectedRowsData,
          },
        };
        this.sessionStorageService.setSessionStorage(StorageConstants.screenContext, JSON.stringify(updatedScreenContext));
    }

    /**
     * Retrieves fields from the specified application object asynchronously.
     * It generates the application object fields from the application object ID and returns them
     * as an Observable.
     *
     * @param {string} appObjectId - The ID of the application object to fetch fields from.
     * @returns {Observable<any>} An Observable that emits the fields or null if an error occurs.
     */
    public getFieldsFromAppObject(appObjectId: string): Observable<any> {
        // Create an Observable that generates the application object fields from the application object ID
        return new Observable((observer) => {
            // Generate the application object fields and handle the result
            this.generateAppObjectFields(appObjectId, 'AppObject')
                .then((fields: any) => {
                    // If the fields are generated successfully, emit the fields and complete the Observable
                    observer.next(fields);
                    observer.complete();
                })
                .catch((error: any) => {
                    // If an error occurs, log the error and emit null and complete the Observable
                    console.log('An error occurred while generating app object fields:', error);
                    observer.next(null);
                    observer.complete();
                });
        });
    }

    /**
     * Retrieves the CRUD app object from either the given app object ID or DSQ ID.
     * It returns an Observable that emits the CRUD app object or null if an error occurs.
     *
     * @param {string} appObjectId - The ID of the app object to retrieve.
     * @param {string} dataSourceQueryId - The ID of the DSQ to retrieve the app object from. Optional.
     * @returns {Observable<any>} An Observable that emits the CRUD app object or null if an error occurs.
     */
    public getCRUDAppObject(appObjectId: any, dataSourceQueryId?: any) {
        let appObject: any;

        try{
            // Retrieve the app object from the app object ID or DSQ ID
            if (appObjectId) {
                appObject = getAppObject(appObjectId);
            } else if (dataSourceQueryId) {
                appObject = getAppObjectFromDataSourceQueryID(dataSourceQueryId);
            }
    
            // Check if the app object is invalid
            if (!appObject) {
                throw new Error('Invalid appObject');
            }
    
            // If the app object is a CRUD app object, retrieve the CRUD app object from the app object ID
            if (appObject?.ObjectType === 2) {
                appObject = getAppObject(appObject?.CRUDAppObjectId);
            }
    
        } catch (error) {
            console.error('An error occurred while getting app object details:', error);
            appObject = null;
        }

        return appObject;
    }

    public async loadApp() {
        // Step 1: Wait for the schema response
        const schemaResponse: any = await firstValueFrom(this.appHelper.getSchema() as any);
        Common.tabJson = JSON.parse(schemaResponse?.Result ?? '{}');
        this.idbService.setItem(StorageConstants.tabData, Common.tabJson);
    
        // Step 2: Wait for both user personalization and permissions in parallel
        const [userPersonalizationResponse, userPermissionsResponse]: any = await Promise.all([
            firstValueFrom(this.getDSQData(Constants.Get_UserWise_Personalize_Data_Id)),
            firstValueFrom(this.appHelper.getPermission() as any)
        ]);
    
        // Step 3: Handle user personalization data
        Common.tabUserPersonalizedData = userPersonalizationResponse?.Result ?? [];
        this.idbService.setItem(StorageConstants.tabUserPersonalizedData, Common.tabUserPersonalizedData);
    
        // Step 4: Handle user permissions data
        Common.tabUserPermissions = userPermissionsResponse?.Result ?? [];
        this.sessionStorageService.setSessionStorage(Constants?.tabUserPermissions, Common.tabUserPermissions);
        // Step 5: Handle Tenant Configurations data
        const configurations: any = await firstValueFrom(this.getDSQData(
            Constants.GetConfigurations_Id));
        this.sessionStorageService.setSessionStorage(Constants?.configurations, configurations?.Result)
    }
 
    /**
     * Toggles the full screen mode of the given HTML element.
     *
     * If the element is not in full screen mode, it will request the full screen
     * mode. If the element is already in full screen mode, it will exit the full
     * screen mode.
     *
     * @param element - The HTML element to toggle full screen mode for.
     */
    public toggleFullScreen(element: HTMLElement): void {
        try {
            if (!document.fullscreenElement) {
                element.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        } catch (error) {
            console.error('Failed to toggle full screen mode', error);
        }
    }
}

// Fetch the data from the DSQ using the DSQ ID and parameters
// this.fetchDSQData(dsqId, reqtokens, params)
//     .then((result: any) => {
//         // If the data is retrieved successfully, return the result
//         observer.next(result);
//         observer.complete();
//     })
//     .catch((error: any) => {
//         // If an error occurs, log the error and return null
//         console.error('An error occurred while fetching data from DSQ ID:', error);
//         observer.next(null);
//         observer.complete();
//     });

// /**
//  * Fetches data from the specified data source query (DSQ).
//  * It retrieves the application object and DSQ details using the DSQ ID.
//  * If the DSQ and application object are found, it creates a payload with the DSQ name,
//  * application object name, request tokens, and additional parameters.
//  * It then executes the DSQ with the payload using the tabGetService.
//  *
//  * @param {string} dsqId - The ID of the DSQ to fetch data from.
//  * @param {any} reqtokens - The request tokens to include in the payload.
//  * @param {any} params - Additional parameters to include in the payload.
//  * @returns {Promise<any>} A Promise that resolves to the result of the DSQ execution.
//  */
// private fetchDSQData(dsqId: string, reqtokens?: any, params?: any): Promise<any> {
//     const appObject = getAppObjectFromDataSourceQueryID(dsqId)?.ObjectName;
//     const dsq = getDataFromDataSourceQueryID(dsqId)?.QueryName;

//     if (!dsq || !appObject) {
//         throw new Error('DSQ or AppObject not found');
//     }

//     const payload = {
//         AppObjectName: appObject,
//         DSQName: dsq,
//         Reqtokens: reqtokens,
//         ...params
//     };

//     return firstValueFrom(this.tabGetService.executeRAWDSQWithName(payload));
// }

// return new Observable((observer) => {
//     // Process the CRUD app object from the given app object ID or DSQ ID
//     this.processCRUDAppObject(appObjectId, dataSourceQueryId)
//         .then((result: any) => {
//             // If the app object is retrieved successfully, emit the result and complete the Observable
//             observer.next(result);
//             observer.complete();
//         })
//         .catch((error: any) => {
//             // If an error occurs, log the error and emit null and complete the Observable
//             console.error('An error occurred while getting app object details:', error);
//             observer.next(null);
//             observer.complete();
//         });
// });

// /**
//  * Processes the CRUD app object from the given app object ID or DSQ ID.
//  * If the app object ID is provided, it retrieves the app object from the app object ID.
//  * If the DSQ ID is provided, it retrieves the app object from the DSQ ID.
//  * If the app object is invalid, it throws an error.
//  * If the app object is a CRUD app object, it retrieves the CRUD app object from the app object ID.
//  * @param {string} appObjectId - The ID of the app object to process.
//  * @param {string} dataSourceQueryId - The ID of the DSQ to process.
//  * @returns {Promise<any>} A Promise that resolves to the processed app object.
//  */
// private async processCRUDAppObject(appObjectId: any, dataSourceQueryId?: any) {
//     let appObject: any;

//     // Retrieve the app object from the app object ID or DSQ ID
//     if (appObjectId) {
//         appObject = getAppObject(appObjectId);
//     } else if (dataSourceQueryId) {
//         appObject = getAppObjectFromDataSourceQueryID(dataSourceQueryId);
//     }

//     // Check if the app object is invalid
//     if (!appObject) {
//         throw new Error('Invalid app object');
//     }

//     // If the app object is a CRUD app object, retrieve the CRUD app object from the app object ID
//     if (appObject?.ObjectType === 2) {
//         appObject = await getAppObject(appObject?.CRUDAppObjectId);
//     }

//     // Return the processed app object
//     return appObject;
// }