export default {
    "label": "Select",
    "labelPosition": "top",
    "widget": "choicesjs",
    "placeholder": "",
    "description": "",
    "tooltip": "",
    "customClass": "",
    "tabindex": "",
    "hidden": false,
    "hideLabel": false,
    "uniqueOptions": false,
    "autofocus": false,
    "disabled": false,
    "tableView": true,
    "modalEdit": false,
    "multiple": false,
    "dataSrc": "values",
    "defaultValue": "",
    "data": {
        "values": [
            {
                "label": "",
                "value": ""
            }
        ],
        "resource": "",
        "url": "",
        "json": "",
        "custom": ""
    },
    "dataType": "",
    "idPath": "id",
    "valueProperty": "",
    "template": "<span>{{ item.label }}</span>",
    "refreshOn": "",
    "refreshOnBlur": "",
    "clearOnRefresh": false,
    "searchEnabled": true,
    "selectThreshold": 0.3,
    "readOnlyValue": false,
    "customOptions": {},
    "useExactSearch": false,
    "persistent": true,
    "protected": false,
    "dbIndex": false,
    "encrypted": false,
    "clearOnHide": true,
    "customDefaultValue": "",
    "calculateValue": "",
    "calculateServer": false,
    "allowCalculateOverride": false,
    "validateOn": "change",
    "validate": {
        "required": false,
        "onlyAvailableItems": false,
        "customMessage": "",
        "custom": "",
        "customPrivate": false,
        "json": "",
        "strictDateValidation": false,
        "multiple": false,
        "unique": false
    },
    "unique": false,
    "errorLabel": "",
    "errors": "",
    "key": "select",
    "tags": [],
    "properties": {},
    "conditional": {
        "show": null,
        "when": null,
        "eq": "",
        "json": ""
    },
    "customConditional": "",
    "logic": [],
    "attributes": {},
    "overlay": {
        "style": "",
        "page": "",
        "left": "",
        "top": "",
        "width": "",
        "height": ""
    },
    "Permission": {
        "componentValue": {}
    },
    "type": "select",
    "indexeddb": {
        "filter": {}
    },
    "dataGridLabel": false,
    "lazyLoad": true,
    "selectFields": "",
    "searchField": "",
    "searchDebounce": 0.3,
    "minSearch": 0,
    "filter": "",
    "limit": 100,
    "authenticate": false,
    "ignoreCache": false,
    "redrawOn": "",
    "input": true,
    "prefix": "",
    "suffix": "",
    "showCharCount": false,
    "showWordCount": false,
    "allowMultipleMasks": false,
    "addons": [],
    "fuseOptions": {
        "include": "score",
        "threshold": 0.3
    }
}