import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, finalize, shareReplay } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache = new Map<string, HttpEvent<unknown>>();
  private queue = new Map<string, Observable<HttpEvent<unknown>>>();

  /**
   * Intercepts HTTP requests and caches responses for GET requests to 'GetScreen' endpoint.
   * @param request - The incoming HTTP request.
   * @param next - The next interceptor in the chain.
   * @returns An Observable that emits an HttpEvent.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is a GET request to 'GetScreen' endpoint
    const isGetScreenRequest = request.url && request.url.includes('GetScreen?');
    const requestUrl = request.urlWithParams;

    // If the request is a GET request to 'GetScreen' endpoint, check if it is already being processed or has already been processed
    if (isGetScreenRequest) {
      const queuedRequest = this.queue.get(requestUrl);
      if (queuedRequest) {
        return queuedRequest;
      }

      const cachedResponse = this.cache.get(requestUrl);
      if (cachedResponse) {
        return of(cachedResponse);
      }
    }

    // If the request is not already being processed or has not been processed, clone the request and process it
    const sharedRequest = next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        // If the response is an HttpResponse, cache it
        if (event instanceof HttpResponse) {
          this.cache.set(requestUrl, event.clone());
        }
      }),
      finalize(() => this.queue.delete(requestUrl)), // Delete the request from the queue once it has been processed
      shareReplay() // Share the response with multiple subscribers
    );

    // If the request is a GET request to 'GetScreen' endpoint, add the request to the queue
    if (isGetScreenRequest) {
      this.queue.set(requestUrl, sharedRequest);
    }

    return sharedRequest;
  }
}
