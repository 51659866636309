<div *ngIf="isPopupVisible" id="notification-popup" class="notification-popup" #popup>
  <!-- Notification Header -->
  <div class="notification-header">
    <div class="notification-header-close">
      <span class="action-icon"><i class="ph ph-x" (click)="closeNotificationPopup()"></i></span>
    </div>
    <div class="notification-title">Notifications</div>
    <div class="notification-header-action">
      <span class="action-icon"><i class="e-all e-icons"></i></span>
    </div>
  </div>

  <!-- Notification Items -->
  <div class="notification-item-container" (scroll)="onScroll($event)">
    <!-- Loader -->
    <div *ngIf="isLoading" class="loader-container">
      <div class="loader-class"></div>
    </div>
    

    <!-- No Notifications Message -->
    <div *ngIf="notifications.length === 0 && !isLoading" class="no-notifications">
      <div class="no-notifications-icon">
        <i class="e-icons e-border-box"></i>
      </div>
      No new notifications
    </div>

    <!-- Notifications List -->
    <div *ngFor="let notification of notifications" class="notification-item">
      <div class="notification-content">
        <div class="notification-icon">
          <i class="e-icons e-border-box"></i> <!-- Add specific notification icon if necessary -->
        </div>
        <div class="notification-details" (click)="openNotification(notification)">
          <div class="notification-type">{{ notification.templateIdentifier }}</div>
          <div class="notification-message">{{ notification.content }}</div>
          <div class="notification-time">{{ notification.createdAt | date: 'short' }}</div>
        </div>
        <div class="notification-action">
          <div class="notification-action-icons mark-icon" (click)="markAsRead(notification)">
            <span class="blue-dot" [ngClass]="{'read': notification.read}"></span>
          </div>
          <div class="notification-action-icons remove-icon" (click)="removeNotification(notification)">
            <i class="e-close e-icons" style="font-size: 10px;"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
