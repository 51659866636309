// Angular Core Imports
import { CommonModule } from '@angular/common';
import { Component, HostListener, ViewContainerRef, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

// Application Services Imports
import { OnlineStatusService } from './core/services/online-status.service';
import { TabUtilsService } from './core/services/tab-utils.service';

// Application Components Imports
import { LoaderComponentComponent } from './layouts/loader-component/loader-component.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, LoaderComponentComponent, CommonModule, ConfirmationDialogComponent],
})
export class AppComponent {
  title = 'tab-rewrite-without-fuse';
  private container: ViewContainerRef = inject(ViewContainerRef);
  public onlineStatus: OnlineStatusService = inject(OnlineStatusService);
  private tabUtilsService: TabUtilsService = inject(TabUtilsService);

  /**
   * Constructor for the AppComponent.
   * Registers the form designer and tab child screen components with the injector.
   * Sets up window events for opening the sidebar and modal.
   */
  constructor() {
    // Set up window events for opening the sidebar and modal
    this.windowEvents();
  }

  // Set up window events for opening the sidebar and modal
  private windowEvents() {
    window.addEventListener('openTabSidebar', (event: any) => {
      this.createDynamicComponent('SidebarComponent', event.detail);
    });

    window.addEventListener('openTabModal', (event: any) => {
      this.createDynamicComponent('DialogComponent', event.detail);
    });

    window.addEventListener('tabUtilsEvent', (event: any) => {
      this.executeTabUtilsEvent(event.detail);
    });
  }

  /**
   * Dynamically loads and creates a component.
   * @param {string} componentName - The name of the component to be loaded.
   * @param {any} data - Data to pass to the component.
   */
  private async createDynamicComponent(componentName: string, data: any) {
    let componentModule: any;
    
    // Lazy load the required component based on its name
    switch (componentName) {
      case 'SidebarComponent':
        componentModule = await import('./shared/sidebar/sidebar.component');
        break;

      case 'DialogComponent':
        componentModule = await import('./shared/dialog/dialog.component');
        break;

      default:
        console.error('Component not found for lazy loading');
        return;
    }

    // Create the dynamic component using the ViewContainerRef
    const componentRef: any = this.container.createComponent(componentModule[componentName]);
    componentRef.instance.data = data;

    componentRef?.instance?.destroyInstance?.subscribe((screenId: string) => {
      if (screenId === data.screenId) {
        componentRef?.destroy();
      }
    });
  }

  /**
   * Executes a method in the TabUtilsService by its event name.
   * @param {any} args - The event arguments containing the event name and data.
   */
  executeTabUtilsEvent(args: any) {
    // Check if the event name exists in the TabUtilsService
    if ((this.tabUtilsService as any)[args.eventName]) {
      // Call the method with the passed data
      (this.tabUtilsService as any)[args.eventName](args.data);
    }
  }

  /**
   * Closes the sidebar when the escape key is pressed.
   * @param {KeyboardEvent} event - The keyboard event.
   */
  @HostListener('document:keydown.escape', ['$event'])
  onEscPress(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      const elements = Array.from(document.querySelectorAll('app-sidebar')) as HTMLElement[];
      const lastElement = elements.pop();
      if (lastElement) {
        lastElement.remove();
      }
    }
  }
}
