import { inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Info, SessionStorageService, StorageConstants, TabGetService } from '@techextensor/tab-core-utility';
import { Title } from '@angular/platform-browser';
import { Constants } from '../const/constants';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class OrgInfoResolver {
  private readonly _title: Title = inject(Title);
  private readonly _tabGetService: TabGetService = inject(TabGetService);
  private readonly _sessionStorageService: SessionStorageService = inject(SessionStorageService);
  private readonly _tabToasterService: ToastrService = inject(ToastrService);


  constructor() {
    const historyState = window.history.state;

    if (historyState?.error) {
      this._tabToasterService.error(`${historyState.error} does not exist.`, 'Error', { positionClass: 'toast-bottom-right' });
      history.replaceState({}, '', window.location.href);
    }
  }

  public resolve() {
    const { subdomain, domain } = this.extractDomainInfo() ?? {};
    const reqtokens = subdomain ? { Subdomain: subdomain } : { Id: Constants.TenantId };

    return this._tabGetService.executeRAWDSQWithName({
      AppObjectName: Constants.TABD_OrgInfo,
      DSQName: Constants.Get_Subdomain_TABD_OrgInfo,
      Reqtokens: reqtokens,
    }).pipe(map((orgInfoRes: any) => {
      if (orgInfoRes?.IsSuccess && orgInfoRes?.StatusCode === '200') {
        const result = orgInfoRes?.Result?.[0];
        if (subdomain && !result) {
          history.pushState({ error: subdomain }, '', window.location.href);
          window.location.href = `https://${domain}`;
          return of(null);
        } else {
          this._sessionStorageService.setSessionStorage(StorageConstants.TenantId, result?.Id);
          return this.processOrgInfo(result);
        }
      } else {
        throw new Error('Invalid or empty response');
      }
    }),
      catchError((error: any) => {
        console.error('Error fetching organization information', error);
        return of(null);
      })
    );
  }

  private extractDomainInfo() {
    const hostname = window.location.hostname;

    // Check if it's an IP address
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (ipRegex.test(hostname)) {
      return { subdomain: null, domain: hostname };
    }

    // Split the hostname by dots
    const parts = hostname.split('.');

    // Handle different cases based on the number of parts
    if (parts.length <= 2) {
      return { subdomain: null, domain: hostname };
    } else {
      const domain = parts.slice(-2).join('.');
      const subdomain = parts.slice(0, -2).join('.');
      return { subdomain, domain };
    }
  }

  private processOrgInfo(result: any) {
    try {
      const infoData: Info = JSON.parse(result?.LocaleSettings || '{}');
      infoData.OrganizationName = result?.OrganizationName;

      this.updatePageTitle(infoData?.OrganizationName);
      this.updateFavicon(infoData?.FavIcon);

      return infoData;
    } catch (error) {
      console.error('Error processing organization info', error);
      throw error;
    }
  }

  private updatePageTitle(title: string): void {
    this._title.setTitle(title);
  }

  private updateFavicon(faviconUrl: string): void {
    const favIcon: any = document.querySelector('#appIcon');
    if (favIcon) {
      favIcon.href = faviconUrl;
    }
  }
}

// getTenantIdFromUrl() {
// const url = window.location.href;
// const queryString = url.split('?')[1];

// if (!queryString) {
//   return null;
// }
// const params = new URLSearchParams(queryString);

// const TenantId = params.get('TenantId') || params.get('tenantId');
// return TenantId;
// }

// private getSubdomainFromUrl(): string | null {
//   const hostname = window.location.hostname;
//   const parts = hostname.split('.');

//   if (parts.length > 2) {
//     return parts[0];
//   }

//   return null;
// }
