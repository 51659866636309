@if(!(onlineStatus.isOnline() | async)){
    <div class="connection-lost">
        <span class="connection-warning-icon">⚠️</span>
        Connection Lost! Please check your internet connection.
    </div>
}
<router-outlet />
<app-loader-component></app-loader-component>
<app-confirmation-dialog></app-confirmation-dialog>
    
    
