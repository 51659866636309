export const environment = {
    name: 'Development',
    // apiUrl: 'http://27.109.17.250:8449/api/v1/',
    apiUrl: 'https://api.techappforce.dev/api/v1/',
    Workflow_URL: 'https://elsa.techextensor.com/',
    // TAB_Rewrite
    // FINAL_CRM
    commonConfig: {
        DEFAULT_APPCODE: 'TAB_Rewrite', // we are using this in sign up component , once something batter strcuture will final we will remove this 
        applicationCode: 'TAB_Rewrite',
        staticToken: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJHdWVzdFVzZXIxMjM0NTY3QHlvcG1haWwuY29tIiwianRpIjoiNzk0ODMyMjgtYzMyOC00NjczLTk1MTAtNjZiMTUyMGYzNTIxIiwiZW1haWwiOiJHdWVzdFVzZXIxMjM0NTY3QHlvcG1haWwuY29tIiwiaWQiOiJkNmU5MjdmZi00MDY2LTQyYTctOWYyMi0xMTc4ZjU5OTFjYWMiLCJsb2NhbGVTZXR0aW5nIjoie1wiVGltZVpvbmVJZFwiOm51bGwsXCJMb2NhbGVcIjowLFwiTGFuZ3VhZ2VcIjowLFwiRGF0ZUZvcm1hdFwiOm51bGwsXCJUaW1lRm9ybWF0XCI6bnVsbCxcIk51bWJlckZvcm1hdFwiOm51bGwsXCJDdXJyZW5jeVwiOm51bGx9Iiwicm9sZSI6Ikd1ZXN0IiwiUm9sZUlkcyI6ImRkMzJiZTI0LTg0YTEtNDI3ZC1iOWVhLThiYjQxNGIxYmJkNiIsIm5iZiI6MTcyMDc4MzQyNSwiZXhwIjoxNzUyMzE5NDI1LCJpYXQiOjE3MjA3ODM0MjV9.KyjRXmrT9P6OY2dnrd4rAa1ujPcKVkGGDWhdrt_CQio',
        tabJson: null,
        tabUserPersonalizedData: null,
        tabUserPermissions: null,
        applicationIdentifier: 'evhyJTadc5yl',
        backendUrl: 'https://novuapi.phoenixassurance.co.in',
        socketUrl: 'https://novuws.phoenixassurance.co.in',
        applicationId: '92A3F57F-EB81-42D9-BCBB-DCAF9420D3D3',
    }
};