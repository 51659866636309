import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { API_URL, Common, Workflow_URL } from '@techextensor/tab-core-utility';
import { provideAuth } from './core/providers/auth.provider';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { environment } from '../environments/environment';

export function initApp() {
  return () => Common.init(environment.commonConfig);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()), // HTTP client with interceptors
    provideAuth(),
    provideToastr(), // Toastr for notifications

    // Init App
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
    },

    // API Configurations
    {
      provide: API_URL,
      useValue: environment.apiUrl
      // useValue: 'http://27.109.17.250:8449/api/v1/'
      // useValue: 'https://pmsapi.techextensor.com/api/v1/'
    },
    {
      provide: Workflow_URL,
      useValue: environment.apiUrl
      // useValue: 'https://elsa.techextensor.com/'
    },

    // HTTP Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true, // Allow multiple interceptors
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true, // Allow multiple interceptors
    }
  ]
};
