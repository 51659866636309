import { Injectable } from '@angular/core';
import { Observable, fromEvent, mapTo, merge, startWith } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OnlineStatusService {
    private online$: Observable<boolean>;

    constructor() {
        this.online$ = merge(
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        ).pipe(startWith(navigator.onLine));
    }
    isOnline(): Observable<boolean> {
        return this.online$;
    }
}
