import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoadingService } from '../../core/services/loading.service';

@Component({
  selector: 'app-loader-component',
  standalone: true,
  imports: [NgIf,CommonModule],
  templateUrl: './loader-component.component.html',
  styleUrl: './loader-component.component.scss'
})
export class LoaderComponentComponent {
  loading: boolean = false;
  constructor(private loaderService: LoadingService) {
    this.loaderService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
   }
}
