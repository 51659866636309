import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { Constants } from '../const/constants';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export  const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
    const _sessionStorageService = inject(SessionStorageService);
    let headers = req.headers;

  // Check if the request contains a custom header marker
  const skipInterceptor = req.headers.get('X-Skip-Interceptor');

  if (!skipInterceptor) {
    // temp patch
    headers = headers.append('Content-Type', 'application/json');

    if(_sessionStorageService.getSessionStorage(StorageConstants.token)){
        headers = headers.set('Authorization',_sessionStorageService.getSessionStorage(StorageConstants.token));
    }

    if(_sessionStorageService.getSessionStorage(StorageConstants.staticToken) && !_sessionStorageService.getSessionStorage(StorageConstants.token)){
      headers = headers.set("Authorization", _sessionStorageService.getSessionStorage(StorageConstants.staticToken));
    }

    if(_sessionStorageService.getSessionStorage(StorageConstants.applicationCode)){
        headers = headers.set(StorageConstants.applicationCode, _sessionStorageService.getSessionStorage(StorageConstants.applicationCode));
    }

    headers = headers.set(StorageConstants.TenantId, _sessionStorageService.getSessionStorage(StorageConstants.TenantId) ? _sessionStorageService.getSessionStorage(StorageConstants.TenantId) : Constants.TenantId );
    headers = headers.set(
      StorageConstants.AppId,
      _sessionStorageService.getSessionStorage(StorageConstants.AppId)
        ? _sessionStorageService.getSessionStorage(StorageConstants.AppId)
        : Constants.AppId
    );
}else {
        // Remove the custom header marker
        headers = headers.delete('X-Skip-Interceptor');
      }
       const newReq = req.clone({ headers });

    return next(newReq);
};