import { Component, ViewChild, inject } from '@angular/core';
import { TreeViewAllModule } from '@syncfusion/ej2-angular-navigations';
import { Router } from '@angular/router';
import { Constants } from '../../core/const/constants';
import { HelperFunctionService } from '../../core/services/helper-function.service';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';

@Component({
  selector: 'app-menu-bar',
  standalone: true,
  imports: [TreeViewAllModule],
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.scss',
})
export class MenuBarComponent {
  public fields: any;
  @ViewChild('menuTree') menuTree: any;
  private readonly router: Router = inject(Router);                 
  private helperFunctionService: HelperFunctionService = inject(HelperFunctionService);
  private sessionStorageService: SessionStorageService = inject(SessionStorageService);
  constructor() {}

  ngOnInit(): void {
    //Load menu data

    // this.helperFunctionService.getDSQData(Constants.Default_TABMD_Menu_Items_Id,{AppId:this.sessionStorageService.getSessionStorage('AppId')?this.sessionStorageService.getSessionStorage('AppId'):''})
    this.helperFunctionService.getDSQData(Constants.Default_TABMD_Menu_Items_Id)
    .subscribe((response: any) => {
      this.fields = this.generateMenuFieldsFromData(response?.Result ?? []);
      // Update the selected menu item based on the current URL
      this.updateActiveMenuItem();
    });
  }

  /**
   * Generates the menu fields from the given data.
   * @param data - The data used to generate the menu fields.
   * @returns The generated menu fields.
   */
  generateMenuFieldsFromData(data: any) {
    let segments = this.router.parseUrl(this.router.url).root.children[
      'primary'
    ].segments;
    let screenId = segments[segments.length - 1].path;
    let fieldData: any = [];
    data.sort((a: any, b: any) => a.Sequence - b.Sequence);

    // Mapping logic simplified
    fieldData = data?.map((item: any) => ({
      id: item['Id'],
      name: item['DisplayName'],
      expanded: false,
      hasChildren: data.some((x: any) => x['ParentID'] === item['Id']),
      parentID: item['ParentID'],
      icon: `e-icons ${item['Icon']}`,
      link: item.ScreenID ? `/screen/${item.ScreenID}` : null,
      selected: item.ScreenID === screenId,
    }));
    return {
      dataSource: fieldData,
      id: 'id',
      text: 'name',
      child: 'child',
      parentID: 'parentID',
      hasChildren: 'hasChildren',
      isChecked: 'isChecked',
    };
  }

  /**
   * Handles the click event on a menu item.
   * If the menu item has a link, navigates to that link.
   * @param args - The event arguments containing the clicked menu item.
   */
  public onMenuItemClicked(args: any) {
    // Get the data of the clicked menu item
    const nodeData = this.menuTree.getTreeData(args.node)[0];
    const ActiveMenuScreenId = nodeData?.link?.match(/\/screen\/(.*)/)[1];
    this.sessionStorageService.setSessionStorage('ActiveMenuScreenId', ActiveMenuScreenId);
    // If the menu item has a link, navigate to that link
    if (nodeData?.link && nodeData?.link !== '') {
      this.sessionStorageService.removeSessionStorage(StorageConstants.screenContext);
      this.router.navigate([nodeData?.link]);
    }
  }

  /**
   * Updates the selected menu item based on the current URL.
   */
  private updateActiveMenuItem() {
    const ActiveMenuScreenId =  this.sessionStorageService.getSessionStorage('ActiveMenuScreenId');
    if (ActiveMenuScreenId) {
      // Find the menu item that matches the link
      const selectedNode = this.fields.dataSource.find(
        (item: any) => item.link === `/screen/${ActiveMenuScreenId}`
      );
      if (selectedNode) {
        // Reset previous selection
        this.fields.dataSource?.forEach((item: any) => (item.selected = false));

        // Set new selection
        selectedNode.selected = true;
        this.expandParentNodes(selectedNode.parentID);
      }
    }
  }

  /**
   * Expands the parent nodes of the selected node.
   * @param parentID - The parent ID of the selected node.
   */
  private expandParentNodes(parentID: any) {
    if (parentID) {
      const parentNode = this.fields.dataSource.find(
        (item: any) => item.id === parentID
      );
      if (parentNode) {
        parentNode.expanded = true;
        this.expandParentNodes(parentNode.parentID);
      }
    }
  }
}
